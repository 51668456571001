import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { splitColumn, removeDuplicates, splitRows } from '../helpers';

const Complaintformanalysis = (props) => {
    let data = props.items;
    let time_stamps = splitColumn(data, "time_stamp");
    let allDates = [];
    let allEventValues = removeDuplicates(splitColumn(data, "event_value"));

    time_stamps.forEach(function(time_stamp){
        let date = time_stamp.split(",")[0];
        allDates.push(date);
    })

    let dates = removeDuplicates(allDates);

    let dataByDates = [];
    dates.forEach(function(date){
        let entries = [];
        let entriesByEventValue = [];
        data.forEach(function(dataItem){
            let time_stamp = dataItem.time_stamp;
            if(time_stamp.split(",")[0] === date){
                entries.push(dataItem);
            }
        })

        let entriesByEvent = []
        allEventValues.forEach(function(event_value){
        //    console.log(entries)
            let eventValues = splitRows(entries, "event_value", event_value);
            let eventLabel = event_value;
            entriesByEvent.push({[eventLabel.toString()] : eventValues.length });
        })

        // let replacementStaus = entriesByEvent["Replacement Status"].eventCount;
        // let orderStaus = entriesByEvent["Want to know order status"].eventCount;
        // let bulkPurchaseQuery = entriesByEvent["Bulk Purchase Query"].eventCount;
        
        dataByDates.push({date: date, replacementStaus: Object.values(entriesByEvent[0])[0], orderStaus: Object.values(entriesByEvent[1])[0], bulkPurchaseQuery: Object.values(entriesByEvent[2])[0], entriesByEvent: entriesByEvent, entries: entries, total: entries.length})
        // let entries = splitRows(data, "time_stamp", date);
    })
    console.log(dataByDates);

    const data1 = [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];
    return (
        <ResponsiveContainer width={'99%'} height={400}>
            <BarChart
        width={1000}
        height={400}
        data={dataByDates.slice(0, 7)}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" stackId="a" fill="#82ca9d" />
        <Bar dataKey="orderStaus" stackId="b" fill="#8884d8" />
        <Bar dataKey="replacementStaus" stackId="b" fill="#ffc658" />
        <Bar dataKey="bulkPurchaseQuery" stackId="b" fill="orange" />
      </BarChart>
        </ResponsiveContainer>
    );
}
export default Complaintformanalysis;
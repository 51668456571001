import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { sumOfColumn, splitRows, splitColumn, removeDuplicates, sumValues, compareStrings } from '../helpers';
import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Reporting = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [ignored, forceUpdate] = useState("");
    const [dates, setDates] = useState([]);
    // const [datesInfo, setDatesInfo] = useState(null);
    // let [startDate, setStartDate] = useState("");
    // let [endDate, setEndDate] = useState("");

    useEffect(() => {
        const getCount = async () => {
            let requestUrI = domainOrigin+"/campaigns-lead-lists";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result);
            }
        }
        getCount()
        // forceUpdate()
    }, [])


    let Allproducts = removeDuplicates(splitColumn(items, "product_name"));
    // console.log({Allproducts});

    let launchPerformace = [];
    Allproducts.forEach(function(product){
        let leadsOfProduct = splitRows(items, "product_name", product );
        // console.log({leadsOfProduct})
        let leadCount = sumOfColumn(splitColumn(leadsOfProduct, "lead_count"));
        let leadCost = sumOfColumn(splitColumn(leadsOfProduct, "lead_cost"));
        let costPerLead = leadCost / leadCount;
        let item = {product: product, leadCount: leadCount, leadCost: leadCost, costPerLead: Math.round(costPerLead) }
        launchPerformace.push(item);
    })

    let allLeadsCost = sumOfColumn(splitColumn(launchPerformace, "leadCost"));
    let allLeadsCount = sumOfColumn(splitColumn(launchPerformace, "leadCount"));
    let HighestCostPerLead = Math.max(...splitColumn(launchPerformace, "costPerLead"));
    let AverageCostPerLead = Math.round(splitColumn(launchPerformace, "costPerLead").reduce((a, b) => a + b, 0) / launchPerformace.length);
    console.log({launchPerformace})

    class CustomizedLabel extends PureComponent {
        render() {
            const { x, y, stroke, value } = this.props;
        
            return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
            );
        }
    }
        
    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
        
            return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {payload.value}
                </text>
            </g>
            );
        }
    }

    return (
        <div className='container-fluid'>
        <div className='row py-2'>
            <div className='col-6 col-sm-6 col-md-3 mb-2'>
                <div className='status-block w-full bg-blue'>
                    <small>Amt Spent: </small>
                    <strong>&#8377;{allLeadsCost}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-6 col-md-3 mb-2'>
                <div className='status-block w-full bg-blue'>
                    <small>Total Leads: </small>
                    <strong>{allLeadsCount}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-6 col-md-3 mb-2'>
                <div className='status-block w-full bg-blue'>
                    <small>Highest Lead Cost: </small>
                    <strong>&#8377;{HighestCostPerLead}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-6 col-md-3 mb-2'>
                <div className='status-block w-full bg-blue'>
                    <small>Avg Lead Cost: </small>
                    <strong>&#8377;{AverageCostPerLead}</strong>
                </div>
            </div>
        </div>
        <ResponsiveContainer width="100%" height={200}>
        <LineChart
        width={500}
        height={200}
        data={launchPerformace}
        syncId="anyId"
        margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="product" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="costPerLead" stroke="#8884d8" fill="#8884d8"  label={<CustomizedLabel />} />
        </LineChart>
        </ResponsiveContainer>
        <p className='w-full text-center'>Trends of <b>Cost Per Lead</b></p>

        <ResponsiveContainer width="100%" height={200}>
        <LineChart
        width={500}
        height={200}
        data={launchPerformace}
        syncId="anyId"
        margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="product" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="leadCost" stroke="#82ca9d" fill="#82ca9d"  label={<CustomizedLabel />} />
        <label content={<CustomizedLabel />} />

        <Brush />
        </LineChart>
        </ResponsiveContainer>
        <p className='w-full text-center'>Trends of <b>Lead Cost</b></p>

        <ResponsiveContainer width="100%" height={200}>
        <AreaChart
        width={500}
        height={200}
        data={launchPerformace}
        syncId="anyId"
        margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="product" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="leadCount" stroke="#82ca9d" fill="#82ca9d"  label={<CustomizedLabel />} />
        </AreaChart>
        </ResponsiveContainer>
        <p className='w-full text-center'>Trends of <b>Lead Count</b></p>
        </div>
    )
} 

export default Reporting;
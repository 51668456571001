import { isLoggedIn } from './helpers';
import Cookies from 'js-cookie';

export function userRoles(){
    let marketingRoles = [
        { name: 'Marketing Exec', code: 'marketing-exec' },
        { name: 'Marketing Manager', code: 'marketing-manager' },
    ];
    let supportRoles = [
        { name: 'Customter Support Exec', code: 'support-exec' },
        { name: 'Customter Support Manager', code: 'support-manager' }
    ];
    let roles = [
        { name: 'Select A Role', code: '' }
    ];
    let crUserRole = isLoggedIn(Cookies).userRole;

    if(crUserRole === "marketing-manager"){
        roles = roles.concat(marketingRoles); 
    }else if(crUserRole === "support-manager"){
        roles = roles.concat(supportRoles);
    }else{
        roles = roles.concat(marketingRoles, supportRoles);
    }
    return roles;
}

export function rolePermissions(){
    let permissions = [
        {role: "marketing-manager", capabilities : [{module: "lead-sources", add: true, update: true, delete: true}, {module: "sc-list", add: true, update: true, delete: true}]},
        {role: "marketing-exec", capabilities : [{module: "lead-sources", add: true, update: true, delete: true}]},
        {role: "support-manager", capabilities : [{module: "sc-list", add: true, update: true, delete: true}]},
        {role: "support-exec", capabilities : [{module: "sc-list", add: true, update: true, delete: false}]},
    ];
    return permissions;
}
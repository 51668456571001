import '../leads/Leads.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component }  from 'react';
import Leadlist from './Leadslist';
import Addcampaigns from './Addcampaign';
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import {isLoggedIn} from '../helpers';
let userData = isLoggedIn(Cookies);

// import ReportView from '../leads/LeadReportView';
// import { InputText } from "primereact/inputtext";
// import { Button } from 'primereact/button';
// import { classNames } from 'primereact/utils';

const Leads = () => {
    const navigate = useNavigate();
    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
        return (
            <div className='leads-board full-width'>
                <div className='full-width'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="card flex flex-column align-items-center py-3 text-left">
                                <Addcampaigns />
                            </div>
                        </div> 
                        <div className='col-12 launch-list pt-3'>
                            <Leadlist/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}
export default Leads;
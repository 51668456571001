import React from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import 'primeicons/primeicons.css';
import Managestaff from "../myaccount/Managestaff";

const Details = ({ userData }) => {
    return (
        <div className='my-account full-width'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 float-none'>
                    <TabView className="full-width" activeIndex={0}>
                        <TabPanel style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',flexDirection:'column'}} header="Dashboard" className="">
                        </TabPanel>
                        <TabPanel style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',flexDirection:'column'}} header="Staff" className="">
                            <Managestaff />
                        </TabPanel>
                        <TabPanel style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',flexDirection:'column'}} header="Profile" className="">
                            <div className='full-width container-fluid'>
                                <div className='row'>
                                    <div className='col-12 col-md-3'>
                                        <div className='user-info w-100 py-2'>
                                            <div className='info-row'><span className='info-label'>Name:</span>{userData.userName}</div>
                                            <div className='info-row'><span className='info-label'>Email:</span>{userData.userEmail}</div>
                                            <div className='info-row'><span className='info-label'>Employee ID:</span>{userData.userEmployeeID}</div>
                                            <div className='info-row'><span className='info-label'>Department:</span>{userData.userEmployeeDepartment}</div>
                                            <div className='info-row'><span className='info-label'>Phone:</span>{userData.userEmployeePhone}</div>
                                            <div className='info-row'><span className='info-label'>Brnach:</span>{userData.userEmployeeBranch}</div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-9'>
                                        <strong>Activity Log</strong>
                                    </div>
                                </div>
                            </div>
                            
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}

export default Details;

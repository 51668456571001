import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios from 'axios';
import { splitColumn} from '../helpers';
// import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

const Eventfeedtable = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [DataCount, setDataCount] = useState(props.DataCount);
    let [listCount, setListCount] = useState(props.listCount);

    let items = props.items;
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "subscriber-list");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };


    const changeState = () => {  
        listCount = listCount + 1;
        setListCount(listCount);
        // console.log(".. "+ listCount)
    }

    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search small" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                <div className="flex align-items-center justify-content-end gap-2">
                    {/* <Button type="button" icon="pi pi-filze" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                    {/* <Button type="button" label="Refresh" onClick={changeState} /> */}
                    <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                    {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    <span className="header-count d-inline-block ml-2">&nbsp;Count: {DataCount}</span>
                </div>
                {/* <Button icon="pi pi-download" /> */}
            </div>
        );
    };
    const header = renderHeader();

    const selectEditor = (selectOptions) => {
        console.log({selectOptions})
        let checked = selectOptions.value;
        let fieldValue = selectOptions.value;
        let value = 1;
        if(fieldValue === true || fieldValue === 1){
            checked = true;
            value = 1;
        }else if(fieldValue === false|| fieldValue === 0){
            checked = false;
            value = 0;
        }
        return <Checkbox onChange={e => selectOptions.editorCallback(e.target.checked)} value={value} checked={checked}></Checkbox>;
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className='row'>
                <div className="data-table-preview full-width">
                    <DataTable className="lead-table" count={items.length} value={items}  paginator scrollable filters={filters} size="small" header={header} 
                    loading={loading} 
                    scrollHeight="70vh" 
                    rows={30} 
                    rowsPerPageOptions={[30, 100, 250]} 
                    tableStyle={{ minWidth: '100%' }} 
                    globalFilterFields={['time_stamp', 'customer_name', 'phone', 'email', 'origin', 'event_name', 'event_value']}>
                        <Column field="time_stamp" header="Time"></Column>
                        <Column field="event_name" header="Event Name"></Column>
                        <Column field="event_value" header="Event Value"></Column>
                        <Column field="origin" header="Origin"></Column>
                        <Column field="customer_name" header="Name"></Column>
                        <Column field="phone" header="Phone"></Column>
                        <Column field="email" header="Email"></Column>
                    </DataTable>
                </div>
            </div>
        )
    }
}

export default Eventfeedtable;
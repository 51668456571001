import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
export const MContext = React.createContext();  //exporting context object


// Parent of Module
const Leadlist = () => {
    // Hierarchy of Side Bar List
    // List
    // |- PreLaunchList
    // |- FetchLeadCount
    
    const [campaign, setCampaign] = useState(null);
    const [error, setError] = useState(null);
    const [Loaded, setLoaded] = useState(false);

    useEffect(() => {
        fetch(domainOrigin+"/product-launches", {
            method: "GET",
        })
        .then((response) => response.json())
        .then((data) => {
            setCampaign(data);
            const LaunchList = data[0];
            setLoaded(true)
        })
        .catch((error) => {
            setError(error)
        });
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!Loaded) {
        return <div>Loading campaign list...</div>;
    } else {
        return(
            <PreLaunchList myLists={campaign} />
        );
    }
    
    }
 
 
    // Child of Module
    function PreLaunchList(props) {  
    const myLists = props.myLists;  
    let LaunchListItems = "";
    if(myLists.length > 0){
        LaunchListItems = myLists.map((key, index) =>
            <div campaign_id={key['campaign_id']} product-name={key['product_name']} table-name={key['table_name']} product-handle={key['product_handle']} className="nav-item col-12 col-sm-4 col-lg-4 col-xl-3" status={index ? 1 : "active"}>
                <Link to={'/product-leads/report'} className="nav-link" state={key}>
                    <span className="product-name"><strong>{key['product_name']}</strong></span>
                    <span className="lead-count">
                        <small>View In Detail</small>
                    </span>
                    {/* <FetchLeadCount tableName={key['table-name']} origin={key['product-handle']} /> */}
                </Link>
            </div>  
        );  
    }else{
        LaunchListItems = <div className="col-12">Campign List Empty</div>;
    }
    
    return (  
        <div className="row">
            {/* {LaunchListItems} */}
                {LaunchListItems}
        </div>
    );  
} 

// Child of Module
const FetchLeadCount = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    let tableName = props.tableName;
    let origin = props.origin;
    console.log(tableName);


    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
        const response = await axios.get(domainOrigin+"/marketing-data?dbname="+tableName+"&origin="+origin+"&dataview=count");
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result);
                let leadCount = result[0]['Total'];
                setItems(leadCount);
            }
            console.log(response)
        }
        getCount()
    }, [])

    if (error) {
        return <div className="lead-count error">Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className="lead-count loading">Loading...</div>;
    } else {
        return (
        <span className="lead-count">
           <strong>Leads:</strong> {items}
        </span>
        );
    }
}

export default Leadlist;

import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { splitColumn } from '../helpers';

// import { ProductService } from './service/ProductService';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
// import { ProductService } from './service/ProductService';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

export default function Leadsbysourcelist(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [countByDate, setCountByDate] = useState("");
    const [rowClick, setRowClick] = useState(true);

    const dataByDate = [];
    // console.log({TableName, productHandle});   
    let [listCount, setListCount] = useState(1);
    const [ignored, forceUpdate] = useState(listCount);

    const [date, setDate] = useState(null);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };
    
    let TableName, productHandle, previewProductName = ""; 

    const cols = [
        { field: 'phone', header: 'Phone' },
        { field: 'email', header: 'Email' },
        { field: 'origin', header: 'Origin' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // const dt = useRef(null);
    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, items);
                doc.save(previewProductName+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, previewProductName);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const [selectedRows, setSelectedRows] = useState([]);
    // console.log(selectedRows);
    function deleteSelectedRows(choice) {
        let selectedRowsData = splitColumn(selectedRows, "Sr");
        let text = "Do you wanna remove "+ selectedRowsData.length + " lead row from list?";
        if (window.confirm(text) == true) {
            axios({
                method: 'delete',
                url: domainOrigin+'/campaigns-lead-lists/remove',
                data: {selectedRowsData}
            })
            .then((response) => {
                // console.log(response);
                if(response.status == 200){
                    alert(response.data);
                    setListCount(listCount + 1);
                    setSelectedRows([]);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    alert(error.message);
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        } else {
            // text = "You canceled!";
        }
        
    }

    const renderHeader = () => {
        let itemsCount = items.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            // console.log(".. "+ listCount);
        }
        return (
            <div className="d-flex flex-wrap justify-content-between">
                <div className="heading pb-2 full-width">
                    <small onClick={changeState}>Showing List of Spends (Count: {itemsCount})</small>
                </div>
                <div className='d-flex justify-content-between full-width'>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search small" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>

                    <div className="flex align-items-center justify-content-end gap-2">
                        {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                        <Button type="button" selected-row={selectedRows.length} className="mx-2 danger" label="Delete" severity="danger" onClick={deleteSelectedRows}/>
                        <Button type="button" label="Refresh" onClick={changeState} />
                        <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                        {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    </div>
                    {/* <Button icon="pi pi-download" /> */}
                </div>
            </div>
        );
    };
    const header = renderHeader();
   
    
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/campaigns-lead-lists";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result);
            }
        }
        getCount()
        // forceUpdate();
    }, [listCount])

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        let editedRow = _items[index];
        // setProducts(_rowData);

        console.log({editedRow})

        const editedRowData = {... editedRow, id : editedRow.id};
        
        axios({
            method: 'post',
            url: domainOrigin+'/campaigns-lead-lists/update',
            data: {editedRowData}
        })
        .then((response) => {
            if(response.status == 200){
                alert("Campaign Row Updated");
                listCount = listCount + 1;
                setListCount(listCount);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

    };
 
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const dateEditor = (options) => {
        return <Calendar className="" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} name='dateRange' selectionMode="single" dateFormat="dd/mm/yy" placeholder={options.value} showButtonBar />;
    };

    // const statusEditor = (options) => {
    //     return (
    //         <Dropdown
    //             value={options.value}
    //             options={statuses}
    //             onChange={(e) => options.editorCallback(e.value)}
    //             placeholder="Select a Status"
    //             itemTemplate={(option) => {
    //                 return <Tag value={option} severity={getSeverity(option)}></Tag>;
    //             }}
    //         />
    //     );
    // };

    // const priceEditor = (options) => {
    //     return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />;
    // };

    // const statusBodyTemplate = (rowData) => {
    //     return <Tag value={rowData.inventoryStatus} severity={getSeverity(rowData.inventoryStatus)}></Tag>;
    // };

    const priceBodyTemplate = (rowData) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.price);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const parsedDate = (rowData) => {
        let date = rowData.date.toLocaleString("en-IN", {timeZone: "Asia/kolkata"});
        console.log({date})
        return (
            <span>{date}</span>
        );
    };


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading Spend History on Lead Sources...</div>;
    } else {
        return (
        <div className="full-width">
                <div className="data-table-preview full-width">
                    <DataTable className="lead-table" value={items} selectionMode={rowClick ? null : 'checkbox'} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} onRowEditComplete={onRowEditComplete} paginator scrollable filters={filters} size="small" header={header} 
                    loading={loading} 
                    scrollHeight="70vh" 
                    rows={30} 
                    editMode="row" 
                    rowsPerPageOptions={[30, 100, 250]} 
                    tableStyle={{ minWidth: '100%' }} 
                    globalFilterFields={['date', 'product_name', 'campaign_group', 'campaign_source']}>
                        <Column selectionMode="multiple"></Column>
                        <Column className="date" editor={(options) => dateEditor(options)} field="date" header="Time" body={parsedDate}></Column>
                        <Column field="product_name" editor={(options) => textEditor(options)} header="Product Name"></Column>
                        <Column field="campaign_group" editor={(options) => textEditor(options)} header="Campaign Group"></Column>
                        <Column field="campaign_source" editor={(options) => textEditor(options)} header="Campaign Source"></Column>
                        <Column field="lead_count" editor={(options) => textEditor(options)} header="Lead Count"></Column>
                        <Column field="lead_cost" editor={(options) => textEditor(options)} header="Lead Cost"></Column>
                        <Column rowEditor header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                </div>
        </div>
        );
    }
}
        
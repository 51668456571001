import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios from 'axios';
import { splitColumn} from '../helpers';
// import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Eventfeedtable from './Eventfeedtable';
import { TabView, TabPanel } from 'primereact/tabview';
import Complaintformanalysis from './Complaintformanalysis';

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
 
const Eventracking = () => {
    const [items, setItems] = useState([]);
    let [listCount, setListCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [DataCount, setDataCount] = useState(0);

    // const [datesInfo, setDatesInfo] = useState(null);
    // let [startDate, setStartDate] = useState("");
    // let [endDate, setEndDate] = useState("");

    useEffect(() => {
        const getCount = async () => {
            let requestUrI = domainOrigin+"/marketing-data/event-tracking";
            // let requestUrI = "https://api.boltt.com/marketing-data/event-tracking";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            if(response.status === 200){
                let result = response.data;
                setDataCount(result.length);
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }

        setInterval(function(){
            getCount()
            console.log("..");
        }, 5000)
        getCount()
        // forceUpdate()
    }, [listCount])

    return (
        <TabView>
            <TabPanel header="Live Feed">
                <Eventfeedtable items={items} loading={loading} isLoaded={isLoaded} DataCount={DataCount} listCount={listCount}/>
            </TabPanel>
            <TabPanel header="Complaint Reg. Form">
                <Complaintformanalysis items={items} loading={loading} isLoaded={isLoaded} DataCount={DataCount} />
            </TabPanel>
        </TabView>
    )
} 

export default Eventracking;
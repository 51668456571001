import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import Leads from './leads/Leads';
import Leadsbysource from './leads-cost-analysis/Leadsbysource';
import Myaccount from './myaccount/Myaccount';
import Mydashboard from './myaccount/Mydashboard';
import Home from './components/Home';
import Servicecenters from './service-centers/Servicecenters';
import ReportView from './leads/LeadReportView';
import Myaccountheader from './myaccount/Myaccountheader';
import SidebarNav from './myaccount/Sidebarnav';
import {isLoggedIn} from './helpers'
import Cookies from 'js-cookie';
import Subscribers from './subscribers/Subscribers';
import Eventracking from './event-tracking/Eventracking';
import Activewristphones from './active-device-analysis/Activewristphones';

let userData = isLoggedIn(Cookies);
const App = () => {
  let sidebarNav = "";
  if(userData.loggedIn === 'true'){
    sidebarNav = <SidebarNav />; 
  }
  return (
    <Router>
        <div className='leads-board view-full-screen'>
            <div className='container-fluid view-full-screen'>
                <div className='row view-full-screen'>
                    {sidebarNav}
                    <div className='main-area report-view col-12 col-md-9 col-lg-10 d-flex flex-column justify-content-start align-items-start text-left'>
                      <Myaccountheader />
                      <div className='py-2 full-width'>
                        <Routes>
                            <Route path='/' element={<Leads />} />
                            <Route path='/product-leads' element={<Leads />} />
                            <Route path='/product-leads/report' element={<ReportView />} />
                            <Route path='/lead-sources' element={<Leadsbysource />} />
                            <Route path='/sc-list' element={<Servicecenters />} />
                            <Route path='/my-account' element={<Myaccount />} />
                            <Route path='/dashboard' element={<Mydashboard />} />
                            <Route path='/subscribers' element={<Subscribers />} />
                            <Route path='/active-wrist-phones' element={<Activewristphones />} />
                            <Route path='/event-tracking' element={<Eventracking />} />
                        </Routes>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Leads /> */}
    </Router>
  );
}

export default App;

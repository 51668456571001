import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../helpers';
import Cookies from 'js-cookie';
console.log("Myaccountheader");

const Myaccountheader = () =>{
    const navigate = useNavigate();
    const [userData, setUserData] = useState(isLoggedIn(Cookies));
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    function handleLogout() {
        Cookies.remove('loggedIn');
        Cookies.remove('userName');
        Cookies.remove('userEmail');
        Cookies.remove('access_allowed');
        Cookies.remove('userEmployeeID');
        Cookies.remove('user_role');
        Cookies.remove('userDepartment');


        

        setIsAuthenticated(false);
        setUserData(null); // Set userData to null when logging out
        navigate("/my-account");
        window.location.reload();
    };

    let LogoutBtn = "";
    let isAuthorized = userData.loggedIn;
    let userName = userData.userName;
    console.log(isAuthorized)

    if(isAuthorized !== undefined){
        LogoutBtn = <Link onClick={handleLogout} className="nav-item nav-link">Logout</Link>
    }

    return(
        <div className='profile-header p-2 bg-white mb-2'>
            <img width="30" height="30" src="/fireboltt-icon.png" alt="platform-logo" class="platform-logo" />
            <ul class="d-flex justify-content-end text-small w-100 account-header navbar-nav">
                <Link to={(userData.loggedIn === undefined) ? '/my-account' : '/dashboard'} className="nav-item nav-link account-link">{(userData.loggedIn === undefined) ? 'Login/Signup' : 'Hi '+userName}</Link>
                {LogoutBtn}
            </ul>
        </div>
    )
}

export default Myaccountheader;
import './leadsbysource.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component }  from 'react';
import Leadsbysourcelist from './Leadsbysourcelist'
import Addleadsbysource from './Addleadsbysource'
import { TabView, TabPanel } from 'primereact/tabview';
import Leadsanalysis from './Leadsanalysis';
import Reporting from './Reporting';
import Fbleads from './Fbleads';

import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import {isLoggedIn} from '../helpers';
let userData = isLoggedIn(Cookies);

const Leadsbysource = () => {
    const navigate = useNavigate();
    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
    return (
        <div className='leads-board full-width'>
            <TabView className="p-0">
                <TabPanel header="Performance" className="p-0">  
                    <Reporting />
                </TabPanel>
                <TabPanel header="Analysis By Products" className="p-0">  
                    <Leadsanalysis />
                </TabPanel>
                <TabPanel header="Add/Update Leads" className="p-0">    
                    <div className='container-fluid full-width'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-3 pt-2 bg-grey'>
                                <Addleadsbysource />
                            </div>
                            <div className='col-12 col-md-12 col-lg-9 launch-list px-0'>
                                <Leadsbysourcelist />
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </div>
    )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}
export default Leadsbysource;
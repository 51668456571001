import { useState, useEffect } from "react";
import React from 'react';
import axios from 'axios';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";        
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Addservicecenter from './Addservicecenter';
import { Button } from 'primereact/button';
import { splitColumn } from '../helpers';

import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import {isLoggedIn} from '../helpers';
import Papa from "papaparse";

let userData = isLoggedIn(Cookies);

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
// import axios from 'axios';
// const domainOrigin = "http://localhost:8080";

// Parent of Module
// import MContext;
const Servicecenters = () => {
    const navigate = useNavigate();
    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
        return (
            <ReportScreen />
        )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}

// Child of Module
function ReportScreen(props){
  return (
    <div className="reportScreen full-width">
        <div className="row">
            <div className="col-12 col-md-12 col-lg-3 col-xl-3 pt-2">
                <Addservicecenter /> 
            </div>
            <div className="col-12 col-md-12 col-lg-9 col-xl-9 launch-list px-0">
                <Fetchservicecenters /> 
            </div>
        </div>
    </div>
  )
}

// Child of Module
const Fetchservicecenters = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    let TableName = props.tableName; 
    let productHandle = props.productHandle; 
    console.log({TableName, productHandle});
    
    const [ignored, forceUpdate] = useState(productHandle);
    let [listCount, setListCount] = useState(1);
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
        let requestUrI = domainOrigin+"/service-centers";
        setIsLoaded(false);
        const response = await axios.get(requestUrI);

            // After fetching data stored it in posts state.
            if(response.status === 200){
            let result = response.data;
            setIsLoaded(true);
            setItems(result);
            setDataCount(result.length);
            // let leadCount = result[0]['Total'];
            // setItems(result);
            // console.log("Data Loaded" + leadCount)
            console.log(result)

            }
        }
        getCount()
        // forceUpdate()
    }, [productHandle, listCount])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [DataCount, setDataCount] = useState(0);
    const [rowClick, setRowClick] = useState(true);

    const cols = [
        { field: 'phone', header: 'Phone' },
        { field: 'email', header: 'Email' },
        { field: 'origin', header: 'Origin' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // const dt = useRef(null);
    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };

    // const exportPdf = () => {
    //     import('jspdf').then((jsPDF) => {
    //         import('jspdf-autotable').then(() => {
    //             const doc = new jsPDF.default(0, 0);

    //             doc.autoTable(exportColumns, items);
    //             doc.save("service-centers"+'.pdf');
    //         });
    //     });
    // };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "service-centers");
        });
    };

    // This state will store the parsed data
    const [data, setData] = useState([]);
            
    // It state will contain the error when
    // correct file extension is not used
    const [error1, setError1] = useState("");

    // It will store the file uploaded by the user
    const [file, setFile] = useState("");
    // Allowed extensions for input file
    const allowedExtensions = ["csv"];  
    const importCSV = () => {
        
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            console.log(e)
        // you can use this method to get file and perform respective operations
            let inputFile =  input.value;
            console.log(inputFile);
            
        
            // Check if user has entered the file
            if (inputFile.length) {
                console.log(inputFile);
                // Check the file extensions, if it not
                // included in the allowed extensions
                // we show the error

                const fileExtension =
                    inputFile.split(".")[1];
                    console.log({fileExtension})
                if (
                    !allowedExtensions.includes(fileExtension)
                ) {
                    setError("Please input a csv file");
                }
    
                // If input type is correct set the state
                setFile(inputFile);

                // If user clicks the parse button without
                // a file we show a error
                if (!inputFile) return alert("Enter a valid file");
        
                // Initialize a reader which allows user
                // to read any file or blob.
                const reader = new FileReader();
        
                // Event listener on reader when the file
                // loads, we parse it and set the data.
                reader.onload = async ({ target }) => {
                    const csv = Papa.parse(target.result, {
                        header: true,
                    });
                    const parsedData = csv?.data;
                    const rows = Object.keys(parsedData[0]);
        
                    const columns = Object.values(parsedData[0]);
                    const res = rows.reduce((acc, e, i) => {
                        return [...acc, [[e], columns[i]]];
                    }, []);
                    console.log(res);
                    setData(res);
                };
                reader.readAsText(inputFile);

            }
            
            const handleParse = () => {
            
                // // If user clicks the parse button without
                // // a file we show a error
                // if (!file) return alert("Enter a valid file");
        
                // // Initialize a reader which allows user
                // // to read any file or blob.
                // const reader = new FileReader();
        
                // // Event listener on reader when the file
                // // loads, we parse it and set the data.
                // reader.onload = async ({ target }) => {
                //     const csv = Papa.parse(target.result, {
                //         header: true,
                //     });
                //     const parsedData = csv?.data;
                //     const rows = Object.keys(parsedData[0]);
        
                //     const columns = Object.values(parsedData[0]);
                //     const res = rows.reduce((acc, e, i) => {
                //         return [...acc, [[e], columns[i]]];
                //     }, []);
                //     console.log(res);
                //     setData(res);
                // };
                // reader.readAsText(file);
            };
            // axios({
            //     method: 'post',
            //     url: domainOrigin+'/service-centers/import',
            //     data: {filePath: "C:\Users\Subham - Boltt\Desktop\wholesale-catalog-sample.csv"}
            // })
            // .then((response) => {
            //     if(response.status == 200){
            //         // alert("Service Center Updated");
            //         setListCount(listCount + 1);
            //     }
            // })
            // .catch((error) => {
            //     // Error
            //     if (error.response) {
            //         // The request was made and the server responded with a status code
            //         // that falls out of the range of 2xx
            //         // console.log(error.response.data);
            //         // console.log(error.response.status);
            //         // console.log(error.response.headers);
            //     } else if (error.request) {
            //         alert(error.message);
            //         // The request was made but no response was received
            //         // `error.request` is an instance of XMLHttpRequest in the 
            //         // browser and an instance of
            //         // http.ClientRequest in node.js
            //         console.log(error.request);
            //     } else {
            //         // Something happened in setting up the request that triggered an Error
            //         console.log('Error', error.message);
            //     }
            //     console.log(error.config);
            // });
        };
        input.click();
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
        console.log(filters)
    };
  
    const calcFilterdData = (name) => {
        let total = 0;
        return total;
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        let editedRow = _items[index];
        // setProducts(_rowData);

        console.log({editedRow})

        const editedRowData = {... editedRow, id : editedRow.id};
        
        axios({
            method: 'post',
            url: domainOrigin+'/service-centers/update',
            data: {editedRowData}
        })
        .then((response) => {
            if(response.status == 200){
                // alert("Service Center Updated");
                setListCount(listCount + 1);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const [selectedRows, setSelectedRows] = useState([]);

    function deleteSelectedRows(choice) {
        let selectedRowsData = splitColumn(selectedRows, "Sr");
        let text = "Do you wanna remove "+ selectedRowsData.length + " service center from list?";
        if (window.confirm(text) == true) {
            axios({
                method: 'delete',
                url: domainOrigin+'/service-centers/remove',
                data: {selectedRowsData}
            })
            .then((response) => {
                console.log(response)
                if(response.status == 200){
                    alert(response.data);
                    setListCount(listCount + 1);
                    setSelectedRows([]);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    alert(error.message);
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        } else {
            // text = "You canceled!";
        }
        
    }

    const renderHeader = () => {
        let itemsCount = items.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            console.log(".. "+ listCount)
        }
        return (
            <div className="d-flex justify-content-between full-width">
                <span className="p-input-icon-left">
                    <i className="pi pi-search small" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                <div className="flex align-items-center justify-content-end gap-2">
                    {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                    <Button type="button" className="mx-1" label="Import From CSV" onClick={importCSV} data-pr-tooltip="CSV" disabled />
                    <Button type="button" className="mx-1" label="Export in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                    <Button type="button" className="mx-1" label="Refresh" onClick={changeState} />
                    <Button type="button" selected-row={selectedRows.length} className="mx-1 danger" label="Delete" severity="danger" onClick={deleteSelectedRows}/>
                    {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    <span className="header-count d-inline-block">Count: {DataCount}</span>
                </div>

                {/* <Button icon="pi pi-download" /> */}
            </div>
        );
    };

    const header = renderHeader();
    // console.log({selectedRows})
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        // setLoading(false);
        return (
        <div className="full-width">
            <div className="data-table-preview widget-layout full-width" >
                <DataTable value={items} selectionMode={rowClick ? null : 'checkbox'} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} paginator scrollable filters={filters} onRowEditComplete={onRowEditComplete} editMode="row" size="small" header={header} loading={loading} globalFilterFields={['Partner', 'Region', 'ASPName', 'Address1', 'Pincode', 'MobileNo', 'TelNo', 'CityName', 'StateName', 'ContactPerson' ]} scrollHeight="80vh" rows={30} rowsPerPageOptions={[30, 100, 250]} tableStyle={{ minWidth: '100%' }}>
                    {/* <Column className="ASPCode" field="ASPCode" header="ASP Code"></Column>
                    <Column className="Region" field="Region" header="Region"></Column> */}
                    <Column selectionMode="multiple"></Column>
                    <Column editor={(options) => textEditor(options)} className="ASPName" field="ASPName" header="ASP Name"></Column>
                    <Column editor={(options) => textEditor(options)} className="Address1" field="Address1" header="Address"></Column>
                    <Column editor={(options) => textEditor(options)} className="Pincode" field="Pincode" header="Pincode"></Column>
                    <Column editor={(options) => textEditor(options)} className="MobileNo" field="MobileNo" header="Mobile No"></Column>
                    <Column editor={(options) => textEditor(options)} className="TelNo" field="TelNo" header="Tel No"></Column>
                    <Column editor={(options) => textEditor(options)} className="CityName" field="CityName" header="City"></Column>
                    <Column editor={(options) => textEditor(options)} className="StateName" field="StateName" header="State"></Column>
                    <Column editor={(options) => textEditor(options)} className="ContactPerson" field="ContactPerson" header="Contact Person"></Column>
                    <Column editor={(options) => textEditor(options)} className="Longitude" field="Longitude" header="Longitude"></Column>
                    <Column editor={(options) => textEditor(options)} className="Latitude" field="Latitude" header="Latitude"></Column>
                    <Column rowEditor header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        </div>
        );
    }
} 

export default Servicecenters;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { UserProfile } from "../userprofile";
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import { isLoggedIn } from '../helpers';
import Details from './Details';

let userEmail = Cookies.get("userEmail");
let userName = Cookies.get("userName");
//let userEmployeeID = Cookies.get("userEmployeeID");
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
  domainOrigin = "https://reportingapi.milgenx.com";
}else{
  domainOrigin = "http://localhost:8080";   
}
const Mydashboard = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(isLoggedIn(Cookies));

  useEffect(() => {
    // Check if the user_email cookie exists to determine if the user is authenticated
    const userEmail = Cookies.get('userEmail');
    if (userEmail) {
      setIsAuthenticated(true);
      // Fetch user data using an API call or from a source as needed
      // For now, let's assume you have user details stored in cookies
      const userName = Cookies.get('userName');
      const userEmployeeID = Cookies.get('userEmployeeID');
      const userEmployeeDepartment = Cookies.get('userEmployeeDepartment');
      const userEmployeePhone = Cookies.get('userEmployeePhone');
      const userEmployeeBranch = Cookies.get('userEmployeeBranch');
      setUserData({
        loggedIn: 'true',
        userName: userName,
        userEmail: userEmail,
        userEmployeeID: userEmployeeID,
        userEmployeeDepartment: userEmployeeDepartment,
        userEmployeePhone: userEmployeePhone,
        userEmployeeBranch: userEmployeeBranch,
        
        
        // Add other user data properties here
      });
      
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogout = () => {
    // Remove the "user_email" cookie when the user logs out
    Cookies.remove('loggedIn');
    Cookies.remove('userName');
    Cookies.remove('userEmail');
    Cookies.remove('userEmployeeID');
    Cookies.remove('userEmployeeDepartment');
    Cookies.remove('userEmployeePhone');
    Cookies.remove('userEmployeeBranch');

    setIsAuthenticated(false);
    navigate("/my-account");
    setUserData(null); // Set userData to null when logging out
    window.location.reload();
  };

  if (userData.loggedIn === 'true') {
    return (
      <>
        {isAuthenticated === true ? (
          <div>
            {/* <h1 style={{display:'flex',alignItems:'center',justifyContent:'center'}}>Welcome, {userName ? userName : 'User'}</h1> */}
            {/* <button onClick={handleLogout}>Logout</button> */}
            <Details userData={userData} />
          </div>
        ) : (
          <h1>You are not authenticated. Please login.</h1>
        )}
      </>
    );
  } else {
    navigate("/my-account");
    return (
      <Notallowed />
    );
  }
}

export default Mydashboard;
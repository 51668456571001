import React, { useState, Component }  from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import axios, {isCancel, AxiosError} from 'axios';
// import { classNames } from 'primereact/utils';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Addcampaigns = () => {
       
    // const [resData, setResData ] = useState([]); 

    const [campaignsData, setcampaignsData] = useState({
        productName : "",
        productHandle : "",
        productTableName : "",
    });
    
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value)
        setcampaignsData({... campaignsData, [name] : value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const newCampaign = {... campaignsData, id : new Date().getTime().toString()};
        
        axios({
            method: 'post',
            url: domainOrigin+'/product-launches/submit',
            data: {newCampaign}
        })
        .then((response) => {
            if(response.status == 200){
                alert("Campaign Added Successfully");
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }
    
    return (
        <form id="addcampaign_form" className='row full-width form-theme' onSubmit={handleSubmit}>
            <div className="col-12 mb-2">
                <strong className='d-block text-left full-width'>Add New Launch</strong>
            </div>
            <div className="col-12 col-md-3 col-lg-3 field-row">
                <InputText className="p-inputtext-sm" placeholder="Product Name" autoComplete="off" name="productName" value={campaignsData.productName} onChange={handleInput} />
            </div>
            <div className="col-12 col-md-3 col-lg-3 field-row">
                <InputText className="p-inputtext-sm" placeholder="Product Handle" autoComplete="off" name="productHandle" value={campaignsData.productHandle} onChange={handleInput} />
            </div>
            <div className="col-12 col-md-3 col-lg-3 field-row">
                <InputText className="p-inputtext-sm" placeholder="Site Leads Directory" autoComplete="off" name="productTableName" value={campaignsData.productTableName} onChange={handleInput} />
            </div>
            <div className="col-12 col-md-3 col-lg-3 field-row">
                <Button type='submit' value="Submit" label="Add New Launch" size="Small" className='small' />
            </div>
        </form>
    )
}
export default Addcampaigns;
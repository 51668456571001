import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios from 'axios';
import { splitColumn} from '../helpers';
// import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
 
const Subscribers = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [ignored, forceUpdate] = useState("");
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [DataCount, setDataCount] = useState(0);
    const [rowClick, setRowClick] = useState(true);
    let [listCount, setListCount] = useState(1);

    // const [datesInfo, setDatesInfo] = useState(null);
    // let [startDate, setStartDate] = useState("");
    // let [endDate, setEndDate] = useState("");

    useEffect(() => {
        const getCount = async () => {
            let requestUrI = domainOrigin+"/marketing-data/fkap";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            if(response.status === 200){
                let result = response.data;
                setDataCount(result.length);
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }
        getCount()
        // forceUpdate()
    }, [listCount])

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;
        let editedRow = _items[index];
        // setProducts(_rowData);

        console.log({editedRow})

        const editedRowData = {... editedRow, id : editedRow.id};
        
        axios({
            method: 'post',
            url: domainOrigin+'/marketing-data/fkap/update',
            data: {editedRowData}
        })
        .then((response) => {
            if(response.status === 200){
                // alert("Service Center Updated");
                setListCount(listCount + 1);
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });

    };

    // const textEditor = (options) => {
    //     return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    // };

    const [selectedRows, setSelectedRows] = useState([]);
    function deleteSelectedRows(choice) {
        let selectedRowsData = splitColumn(selectedRows, "serial_number");
        let text = "Do you wanna remove "+ selectedRowsData.length + " subscriber from list?";
        if (window.confirm(text) === true) {
            axios({
                method: 'delete',
                url: domainOrigin+'/marketing-data/fkap/remove',
                data: {selectedRowsData}
            })
            .then((response) => {
                console.log(response)
                if(response.status === 200){
                    alert(response.data);
                    setListCount(listCount + 1);
                    setSelectedRows([]);
                }
            })
            .catch((error) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.request) {
                    alert(error.message);
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        } else {
            // text = "You canceled!";
        }
        
    }

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "subscriber-list");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };


    const changeState = () => {  
        listCount = listCount + 1;
        setListCount(listCount);
        console.log(".. "+ listCount)
    }

    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search small" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                <div className="flex align-items-center justify-content-end gap-2">
                    {/* <Button type="button" icon="pi pi-filze" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                    <Button type="button" label="Refresh" onClick={changeState} />
                    <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                    <Button type="button" selected-row={selectedRows.length} className="mx-1 danger" label="Delete" severity="danger" onClick={deleteSelectedRows}/>
                    {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    <span className="header-count d-inline-block ml-2">&nbsp;Count: {DataCount}</span>
                </div>
                {/* <Button icon="pi pi-download" /> */}
            </div>
        );
    };
    const header = renderHeader();

    const selectEditor = (selectOptions) => {
        console.log({selectOptions})
        let checked = selectOptions.value;
        let fieldValue = selectOptions.value;
        let value = 1;
        if(fieldValue === true || fieldValue === 1){
            checked = true;
            value = 1;
        }else if(fieldValue === false|| fieldValue === 0){
            checked = false;
            value = 0;
        }
        return <Checkbox onChange={e => selectOptions.editorCallback(e.target.checked)} value={value} checked={checked}></Checkbox>;
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className='row'>
                <div className="data-table-preview full-width">
                    <DataTable className="lead-table" count={items.length} value={items} selectionMode={rowClick ? null : 'checkbox'} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} onRowEditComplete={onRowEditComplete} editMode="row" paginator scrollable filters={filters} size="small" header={header} 
                    loading={loading} 
                    scrollHeight="70vh" 
                    rows={30} 
                    rowsPerPageOptions={[30, 100, 250]} 
                    tableStyle={{ minWidth: '100%' }} 
                    globalFilterFields={['name', 'customer_name', 'phone', 'email', 'origin', 'utm_campaign', 'utm_medium', 'utm_source']}>
                        <Column selectionMode="multiple"></Column>
                        <Column className="time_stamp" field="customer_name" header="customer_name"></Column>
                        <Column field="email" header="email"></Column>
                        <Column field="phone" header="phone"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="marketing_consent" editor={(options) => selectEditor(options)} header="Consent"></Column>
                        <Column field="origin" header="Origin"></Column>
                        <Column field="utm_campaign" header="utm_campaign"></Column>
                        <Column field="utm_medium" header="utm_medium"></Column>
                        <Column field="utm_source" header="utm_source"></Column>
                        <Column rowEditor header="Action" bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                </div>
            </div>
        )
    }
} 

export default Subscribers;
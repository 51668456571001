import { Link, useLocation } from "react-router-dom";
import ReactDOM, { render } from "react-dom";
import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios from 'axios';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";        
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { splitColumn, removeDuplicates } from '../helpers';
// import { ProductService } from './service/ProductService';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar, Legend, Cell, } from 'recharts';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

class ReportView extends React.Component {
    render (){
        return (
            <ReportScreen />
        )
    }
}

// Child of Module
const FetchReportTable = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [countByDate, setCountByDate] = useState("");
    const [campaignDates, setcampaignDates] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // let leadByDate = "";
    const [leadByDate, setLeadByDate] = useState("");
    const dataByDate = [];

    const [date, setDate] = useState(null);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };
    
    let TableName = ""; 
    let productHandle = ""; 
    let previewProductName = "";

    const cols = [
        { field: 'phone', header: 'Phone' },
        { field: 'email', header: 'Email' },
        { field: 'origin', header: 'Origin' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const dt = useRef(null);
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, items);
                doc.save(previewProductName+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, previewProductName);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    let [listCount, setListCount] = useState(1);
    const changeState = () => {  
        listCount = listCount + 1;
        setListCount(listCount);
        console.log(".. "+ listCount)
    }
    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search small" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                <div className="flex align-items-center justify-content-end gap-2">
                    {/* <Button type="button" icon="pi pi-filze" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                    <Button type="button" label="Refresh" onClick={changeState} />
                    <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                    {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                </div>
                {/* <Button icon="pi pi-download" /> */}
            </div>
        );
    };
    const header = renderHeader();
    const location = useLocation();
    const propsData = location.state;

    console.log({propsData});
    if(propsData){
        TableName = propsData["table_name"];
        productHandle = propsData["product_handle"];
        previewProductName = propsData["product_name"];
    }else{
        TableName = props.tableName;
        productHandle = props.productHandle;
    }
    // console.log({TableName, productHandle});   
    // const [ignored, forceUpdate] = useState(listCount);
    const [ignored, forceUpdate] = useState(productHandle);
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/marketing-data?dbname="+TableName+"&origin="+productHandle+"";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            let dates = {};
            let counts = {};
            let countValues = {};
            let campaignSource = [];

            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result);
                console.log(result);

                let result_dates = [];

                result.forEach((item) => {
                    // console.log({item})

                    let time_stamp = item.time_stamp;
                    if(time_stamp){
                        const date = item.time_stamp.split('T')[0];
                        result_dates.push(date);

                        //dates.push(date);
                        if (counts[date]) {
                            counts[date] += 1;
                        } else {
                            counts[date] = 1;
                        }
                    }
                    setCountByDate(counts);
                });

                // All dates of campaign
                result_dates = removeDuplicates(result_dates);
                let firstDay = result_dates[0].split("-");
                let lastDay = result_dates[result_dates.length - 1].split("-");

                result.forEach(function(item){
                    const date = item.time_stamp.split('T')[0];
                    if(date === result_dates[0]){
                        dataByDate.push(item)
                    }
                })
                // leadByDate = dataByDate;

                setLeadByDate(dataByDate);
                setcampaignDates(result_dates);
                setEndDate(lastDay);

                let minDate = new Date();
                minDate.setFullYear(firstDay[0]);
                minDate.setMonth(firstDay[1] - 1);
                minDate.setDate(firstDay[2]);
                setStartDate(minDate);
                console.log({minDate, firstDay})

                let maxDate = new Date();
                maxDate.setMonth(lastDay[1] - 1);
                maxDate.setFullYear(lastDay[0]);
                maxDate.setDate(lastDay[2]);
                console.log({maxDate, lastDay})
                setEndDate(maxDate);

                console.log({date})
                // minDate.setFullYear(prevYear);
                if(countByDate){
                    dates = Object.keys(counts);
                    countValues = Object.values(counts);
                    let chartData = [];
                    result.forEach(function(eachDateItem){
                        let eachDateItemCount = 0;
                        result.forEach(function(dataItem){
                            let time_stamp = dataItem.time_stamp;
                            if(time_stamp != null && time_stamp.includes(eachDateItem)){
                                eachDateItemCount++;
                            }
                        })
                        let chartDataItem = {date:eachDateItem, count: eachDateItemCount};
                        chartData.push(chartDataItem)
                    })
                    console.log({chartData})
                }
                
            }
        }
        getCount()
        // forceUpdate()
    }, [productHandle, listCount])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });


    let parsedEndDate = new Date(endDate).toLocaleDateString('fr-CA', { year:"numeric", month:"numeric", day:"numeric"}) ;
    console.log("endDate: "+endDate, {parsedEndDate});
    
    let onLoadData = "";
    items.forEach(function(item){
        const date = item.time_stamp.split('T')[0];
        if(date === parsedEndDate){
            dataByDate.push(item)
        }
    })

    onLoadData = dataByDate;
    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value).toLocaleDateString('fr-CA', { year:"numeric", month:"numeric", day:"numeric"}) ;
        console.log({selectedDate, endDate});

        items.forEach(function(item){
            const date = item.time_stamp.split('T')[0];
            if(date === selectedDate){
                dataByDate.push(item)
            }
        })
        // leadByDate = dataByDate;
        setLeadByDate(dataByDate);
        // setcampaignsData({... campaignsData, [name] : value });
    }
    if(leadByDate === ""){
        setLeadByDate(onLoadData);
    }
    console.log({dataByDate, leadByDate})

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading Results for {previewProductName}...</div>;
    } else {
        return (
        <div className="full-width">
            <div className="heading full-width">
                <h5>Showing Results For: <strong>{previewProductName}({items.length})</strong></h5>
            </div>
            <TabView className="p-0">
                <TabPanel header="Chart View">
                <div className="col-12 pb-2">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            {/* <Button type="button" label="Refresh" onClick={changeState} /> */}
                            <Calendar className="field-small p-inputtext-sm pt-3 mx-3" value={endDate} onChange={handleDateChange} dateFormat="d MM yy" minDate={startDate} maxDate={endDate} showIcon />
                        </div>
                        <div className="col-12 col-md-6 p-3 text-center chart-view">
                            <ChartCountPerDay data={items} countByDate={countByDate} /> 
                        </div>    
                        <div className="col-12 col-md-6 p-3 text-center chart-view">
                            <ChartCountPerSource data={leadByDate} /> 
                        </div>
                    </div>
                </div>
                </TabPanel>
                <TabPanel header="Site Leads" className="p-0">    
                <div className="data-table-preview full-width">
                    <DataTable className="lead-table" count={items.length} value={items} paginator scrollable filters={filters} size="small" header={header} 
                    loading={loading} 
                    scrollHeight="70vh" 
                    rows={30} 
                    rowsPerPageOptions={[30, 100, 250]} 
                    tableStyle={{ minWidth: '100%' }} 
                    globalFilterFields={['name', 'customer_name', 'phone', 'email', 'origin', 'utm_campaign', 'utm_medium', 'utm_source']}>
                        <Column className="time_stamp" field="time_stamp" header="Time"></Column>
                        <Column field="customer_name" header="Name"></Column>
                        <Column field="phone" header="Phone"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="marketing_consent" header="Consent"></Column>
                        <Column field="origin" header="Origin"></Column>
                        <Column field="utm_campaign" header="utm_campaign"></Column>
                        <Column field="utm_medium" header="utm_medium"></Column>
                        <Column field="utm_source" header="utm_source"></Column>
                    </DataTable>
                </div>
                </TabPanel>
            </TabView>
        </div>
        );
    }
} 

// Child of Module
function ReportScreen(props){
  const [previewTableName, setpreviewTableName] = useState("marketing_data_blizzard");
  const [previewHandleName, setpreviewHandleName] = useState("blizzard");
    
    window.addEventListener("load", function(){
        let leadListbtns = document.querySelectorAll(".launch-list li");
        leadListbtns.forEach(function(leadListbtn){
            leadListbtn.addEventListener("click", function(){
                let tableName = leadListbtn.getAttribute("table-name");
                let productName = leadListbtn.getAttribute("product-name");
                let productHandle = leadListbtn.getAttribute("product-handle");
                if(!leadListbtn.classList.contains("active")){
                    leadListbtns.forEach(function(leadListItem){
                        if(leadListItem.classList.contains("active")){
                            leadListItem.classList.remove("active");
                        }
                    }) 
                    leadListbtn.classList.add("active");
                }
                setpreviewTableName(tableName);
                setpreviewHandleName(productHandle);
            })  
        });
    })
    return (
    <div className="full-width">
        <div className="col-12">
            <FetchReportTable tableName={previewTableName} productHandle={previewHandleName} /> 
        </div>
    </div>
  )
}

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;
    
        return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {value}
        </text>
        );
    }
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;
    
        return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
            </text>
        </g>
        );
    }
}

const ChartCountPerDay = (props) => {
    let data = props.data;
    let dates = {};
    let counts = props.countByDate;
    let countValues = {};
    console.log(props)
    if(data.length > 0){
        // const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];
        if(counts){
            dates = Object.keys(counts);
            countValues = Object.values(counts);
            let chartData = [];
            dates.forEach(function(eachDateItem){
                let eachDateItemCount = 0;
                data.forEach(function(dataItem){
                    let time_stamp = dataItem.time_stamp;
                    if(time_stamp !== null && time_stamp.includes(eachDateItem)){
                        eachDateItemCount++;
                    }
                })
                // let eachDateItemCount = data.values(eachDateItem).length;
                // console.log(eachDateItemCount);
                let chartDataItem = {date:eachDateItem, "Lead Count By Dates": eachDateItemCount};
                chartData.push(chartDataItem)
                // console.log({eachDateItem});
            })
            console.log({chartData})
            if(chartData){
                return (
                    <>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 10,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" height={20} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Lead Count By Dates" stroke="#8884d8" label={<CustomizedLabel />} />
                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </LineChart>
                    </ResponsiveContainer>
                    </>
                )
            }
        }
        
    }
};
const ChartCountPerSource = (props) => {
    const campaignSource = props.data;
    let uniqueSource = removeDuplicates(splitColumn(campaignSource, "utm_source"));
    let countBySource = [];

    if(uniqueSource){
        uniqueSource.forEach(function(uniqueSourceItem){
            let leadCount = 0;
            campaignSource.forEach(function(dataItem){
                if(dataItem["utm_source"] === uniqueSourceItem){
                    leadCount++;
                }
            })
            let addSourceItem = {"name":uniqueSourceItem, "Lead Count By Sources": leadCount};
            countBySource.push(addSourceItem);
        })
    }

    console.log({campaignSource, countBySource})
      const getIntroOfPage = (label) => {
        if (label === 'null') {
          return "Leads from organic source";
        }
        return '';
      };
      
    const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        if(label === "null"){label = "Organic"}
        return (
        <div className="custom-tooltip px-1">
            <small className="label">{`${label} : ${payload[0].value}`}</small>
            <small className="intro">{getIntroOfPage(label)}</small>
            {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
        );
    }
    return null;
    };
    return(
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={countBySource}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis label={<CustomizedLabel/>} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Lead Count By Sources" barSize={20} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    )
};

export default ReportView;
import { useState, useEffect } from "react";
import React from 'react';
import axios from 'axios';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";        
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { sumOfColumn, splitRows, splitColumn, removeDuplicates, sumValues, compareStrings } from '../helpers';

import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import {isLoggedIn} from '../helpers';
import Papa from "papaparse";

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

let userData = isLoggedIn(Cookies);

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
// import axios from 'axios';
// const domainOrigin = "http://localhost:8080";

// Parent of Module
// import MContext;
const Servicecenters = () => {
    const navigate = useNavigate();
    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
        return (
            <Activewristphones />
        )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}

// Child of Module
const Activewristphones = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [rawData, setrawData] = useState([]);

    const [ignored, forceUpdate] = useState();
    let [listCount, setListCount] = useState(1);
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
        let requestUrI = domainOrigin+"/active-wrist-phones";
        setIsLoaded(false);
        const response = await axios.get(requestUrI);

            // After fetching data stored it in posts state.
            if(response.status === 200){
            let result = response.data;
            setrawData(result);
            setIsLoaded(true);
            setItems(result.reverse());
            setDataCount(result.length);
            // let leadCount = result[0]['Total'];
            // setItems(result);
            // console.log("Data Loaded" + leadCount)
            console.log(result)

            }
        }
        getCount()
        // forceUpdate()
    }, [listCount])

    let modelsListItems = [];
    let modelsList = removeDuplicates(splitColumn(rawData, "model_no"));
    modelsListItems = [{name: "Select Model", code: ""}];
    modelsList.forEach(function(modelsListItem){
        modelsListItems.push({name: modelsListItem, code:modelsListItem})
    })


    let swVersionItems = [];
    let swVersionList = removeDuplicates(splitColumn(rawData, "software_version"));
    swVersionItems = [{name: "Select SW Version", code: ""}];
    swVersionList.forEach(function(swVersionListItem){
        swVersionItems.push({name: swVersionListItem, code:swVersionListItem})
    })

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [DataCount, setDataCount] = useState(0);

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "active-wrist-phones");
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    

    // This state will store the parsed data
    const [data, setData] = useState([]);
            
    // It state will contain the error when
    // correct file extension is not used
    const [error1, setError1] = useState("");

    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
        console.log(filters)
    };
  
    let filteredData = items;

    const [dates, setDates] = useState([]);
    const [filterParam, setFilterParam] = useState({
        model : "", swVersion : "", campaignSource : ""
    });

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // setFilterParam({...filterParam, [e.target.name] : e.target.value });
        // setItems(rawData);
        // let selectedModel = "";
        // console.log(filterParam)
        // if(filterParam.model){
        //     let selectedModel = filterParam.model.name;
        //     console.log({selectedModel})
            // setItems(splitRows(rawData, "model_no", selectedModel));
        // }

        // if(filterParam.swVersion){
        //     let selectedSW = filterParam.swVersion.name;
        //     setItems(splitRows(rawData, "software_version", selectedSW));
        // }
        // setItems(splitRows(items, filterParam.swVersion.name)); 
    }

    function onModelChange(e) {
        if(e.target.value.code == ""){
            setItems(rawData);
        }else{
            setItems(splitRows(rawData, "model_no", e.target.value.code));
        }
        setFilterParam({...filterParam, [e.target.name] : e.target.value, ["software_version"]: "" });
    }
    function onSWChange(e) {
        if(e.target.value.code == ""){
            setItems(rawData);
        }else{
            setItems(splitRows(rawData, "software_version", e.target.value.code));
        }
        setFilterParam({...filterParam, [e.target.name] : e.target.value, ["model_no"]: "" });
    }

    const renderHeader = () => {
        let itemsCount = items.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            console.log(".. "+ listCount)
        }

        let exportLabel = "Export "+items.length;

        return (
            <div>
            <div className="d-none justify-content-between full-width">
                <div className="flex align-items-center justify-content-end gap-2">
                    {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                    {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    {/* <span className="header-count d-inline-block">Count: {DataCount}</span> */}
                </div>
                {/* <Button icon="pi pi-download" /> */}
            </div>

            <div className='container-fluid px-0 pt-0 bg-grey'>
                <form id="addcampaign_form" className='row form-theme justify-content-between'>
                    <div className="col-4 col-sm-4 col-md-3 field-row">
                        <span className="p-input-icon-left w-100">
                            <i className="pi pi-search small" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className="search-field"/>
                        </span>
                    </div>
                    <div className="col-4 col-sm-4 col-md-3 field-row">
                        <Dropdown value={filterParam.model} onChange={onModelChange} options={modelsListItems} optionLabel="name" name="model" placeholder="Select Model" className="w-full" />
                    </div>
                    <div className="col-4 col-sm-4 col-md-3 field-row">
                        <Dropdown value={filterParam.swVersion} onChange={onSWChange} options={swVersionItems} optionLabel="name" name="swVersion" placeholder="Select SW Version" className="w-full" />
                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-3 field-row">
                        <Calendar className="" value={dates} onChange={(e) => setDates(e.value)} name='dateRange' minDate="" maxDate="" selectionMode="multiple" dateFormat="dd/mm/yy" placeholder='Select Dates' showButtonBar showIcon />
                    </div> */}
                    <div className="d-flex align-items-center justify-content-end gap-2 col-12 col-sm-12 col-md-3 field-row">
                        <Button type="button" className="mx-1" label={exportLabel} onClick={exportExcel} data-pr-tooltip="XLS" />
                        <Button type="button" className="mx-1" label="Refresh" onClick={changeState} />
                    </div>
                </form>
            </div>
        </div>
        );
    };

    const header = renderHeader();

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        // setLoading(false);
        return (
        <div className="full-width">
            <div className="data-table-preview widget-layout full-width" >
                <DataTable value={items} paginator scrollable filters={filters} size="small" header={header} loading={loading} globalFilterFields={['phone_no', 'model_no', 'software_version', 'sr' ]} scrollHeight="70vh" rows={30} rowsPerPageOptions={[30, 100, 250]} tableStyle={{ minWidth: '100%' }}>
                    {/* <Column className="ASPCode" field="ASPCode" header="ASP Code"></Column>
                    <Column className="Region" field="Region" header="Region"></Column> */}
                    <Column className="sr" field="sr" header="Sr No"></Column>
                    <Column className="timestamp" field="timestamp" header="Time Stamp"></Column>
                    <Column className="imei_no" field="imei_no" header="IMEI No"></Column>
                    <Column className="phone_no" field="phone_no" header="Phone No"></Column>
                    <Column className="model_no" field="model_no" header="Model No"></Column>
                    <Column className="software_version" field="software_version" header="SW Version"></Column>
                </DataTable>
            </div>
        </div>
        );
    }
} 

export default Activewristphones;
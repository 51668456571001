import React, { useState, Component }  from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import axios, {isCancel, AxiosError} from 'axios';
import { Calendar } from 'primereact/calendar';
// import { classNames } from 'primereact/utils';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("analysis.boltt.com")){
    domainOrigin = "https://api.boltt.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Addleadsbysource = () => {
       
    // const [resData, setResData ] = useState([]); 

    const [campaignsData, setcampaignsData] = useState({
        date : "",
        product_name : "",
        campaign_group : "",
        campaign_source : "",
        lead_count : "",
        lead_cost : "",
    });
    
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value)
        setcampaignsData({...campaignsData, [name] : value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let date = campaignsData.date;
        let folteretedDate = date.toLocaleString('en-IN', {timeZone: 'IST'}).split(',')[0].split("/");
        let parsedDate = folteretedDate[2]+"-"+folteretedDate[1]+"-"+folteretedDate[0];
        const newCampaign = {...campaignsData, id : new Date().getTime().toString(), ["date"]: parsedDate };
        axios({
            method: 'post',
            url: domainOrigin+'/campaigns-lead-lists/submit',
            data: {newCampaign}
        })
        .then((response) => {
            if(response.status == 200){
                alert("Campaign Added Successfully");
            }
        })
        .catch((error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                alert(error.message);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }
    
    return (
        <div className="col-12 flex flex-column align-items-center text-left">
            <form id="addcampaign_form" className='row form-theme' onSubmit={handleSubmit}>
                <div className="col-12">
                    <small className='d-block text-left full-width'>Add Spend Per Source</small>
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-12 field-row">
                    <Calendar className="field-small p-inputtext-sm" placeholder="Add Date" name="date" value={campaignsData.date} onChange={handleInput} dateFormat="d MM yy" />
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="Product Name" autoComplete="off" name="product_name" value={campaignsData.product_name} onChange={handleInput} />
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="Campaign Group" autoComplete="off" name="campaign_group" value={campaignsData.campaign_group} onChange={handleInput} />
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="Campaign Source" autoComplete="off" name="campaign_source" value={campaignsData.campaign_source} onChange={handleInput} />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="Lead Count" autoComplete="off" name="lead_count" value={campaignsData.lead_count} onChange={handleInput} />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-12 field-row">
                    <InputText className="p-inputtext-sm" placeholder="Amount Spent" autoComplete="off" name="lead_cost" value={campaignsData.lead_cost} onChange={handleInput} />
                </div>
                <div className="col-12 col-md-4 col-lg-12 field-row">
                    <Button type='submit' value="Submit" label="Add Data" size="Small" className='small' />
                </div>
            </form>
        </div>
    )
}
export default Addleadsbysource;